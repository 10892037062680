import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

const CryptoLicense = lazy(() => import('../pages/cryptoLicense'))
const CryptoLicenseId = lazy(() => import('../pages/cryptoLicense/pages'))
const EmiLicenseId = lazy(() => import('../pages/emiLicense/pages'))
const FintechLicense = lazy(() => import('../pages/fintechLicense'))
const ServiceId = lazy(() => import('../pages/service/pages'))
const Home = lazy(() => import('../pages/home'))
const Contact = lazy(() => import('../pages/contact'))
const AboutUs = lazy(() => import('../pages/aboutUs'))

const _Routes = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/fintech-licenses' element={<FintechLicense />} />
            <Route path='/crypto-license' element={<CryptoLicense />} />
            <Route path='/crypto-license/:id' element={<CryptoLicenseId />} />
            <Route path='/emi-license/:id' element={<EmiLicenseId />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/:id' element={<ServiceId />} />
        </Routes>
    )
}

export default _Routes
